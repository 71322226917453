import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import mixpanel from 'mixpanel-browser';
import { useHistory } from 'react-router-dom';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import {
  StyledPageWrapper,
  StyledHeader,
  StyledSection,
  StyledLeftHeaderSection,
  StyledRightHeaderSection,
  StyledDarkSection,
  StyledTitle,
  StyledSubtitle,
  StyledLoginButton,
  StyledButtonsContainer,
  StyledDetailsContainer,
  StyledFooterContainer,
  StyledContainerLoader,
  StyledLoader,
  StyledDescriptionHeader,
  StyledDescriptionSubheader,
  StyledImgContainer,
  StyledImage,
  StyledTextNavLink,
  StyledMailLink,
  StyledExternalLink,
  StyledStoreLink,
  StyledCenteredContainer,
  StyledLinksWrapper,
  StyledElipse,
  StyledRegisterButton,
  StyledMobileRegisterButton,
  StyledMobileLoginButton,
} from './styled';
import { ReactComponent as LOGO } from '../../assets/img/TOL-Icon.svg';
// import { ReactComponent as LOGOMobile } from '../../assets/img/tol-logo-mobile.svg';
// import Athletic from '../../assets/img/featured/athletic.png';
// import GMGames from '../../assets/img/featured/gmgames.png';
// import Guardian from '../../assets/img/featured/guardian.png';
// import TechCrunch from '../../assets/img/featured/techcrunch.png';
// import TheSun from '../../assets/img/featured/thesun.png';
import DownloadAppStore from '../../assets/img/download-appstore.png';
import DownloadGooglePlay from '../../assets/img/download-gplay.png';
import LandingBig from '../../assets/img/landing-big.png';
import LandingSmall from '../../assets/img/landing-small.png';
import DiscordGameplay from '../../assets/img/image feature 01.png';
import SimpleGameplay from '../../assets/img/image feature 02.png';
import PlayOnline from '../../assets/img/image feature 03.png';
import DailyFootball from '../../assets/img/image feature 04.png';
// import SimpleGameplayMobile from '../../assets/img/simple-gameplay-mobile.png';
// import MultiDeviceScreen from '../../assets/img/multi-device-screen.png';
// import DiscordScreen from '../../assets/img/discord-screen.png';
// import DailyMatch from '../../assets/img/daily-match.png';
// import PatreonSubscription from '../../assets/img/patreon-subscription.png';
import { testAuth } from '../../redux/actions/User';
import { isAuth, showLoader } from '../../redux/selector/User';
import { campaignParams } from '../../service/mixpanel_utm';
import MetaPixel from '../../components/MetaPixel';

const StartPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(isAuth);
  const isLoading = useSelector(showLoader);
  const env = runtimeEnv();
  const { REACT_APP_AUTH_LINK } = env;
  React.useEffect(() => {
    if (isAuthenticated === null) {
      dispatch(testAuth());
    }
    if (isAuthenticated) {
      history.push('/home');
    }
  }, [isAuthenticated, dispatch, history]);

  React.useEffect(() => {
    campaignParams();
  }, []);

  const clickLogin = () => {
    mixpanel.track('Login clicked');
    if (isAuthenticated) {
      history.push('/home');
    } else {
      window.location.href = REACT_APP_AUTH_LINK;
    }
  };

  const clickRegister = () => {
    mixpanel.track('Register button clicked');
    localStorage.setItem('isFromRegister', true);
    history.push('/signup');
  };

  const clickBlog = () => {
    mixpanel.track('Blog clicked');
    history.push('/blog');
  };

  const goToUrl = (url) => window.open(url, '_blank');

  return (
    <>
      {isLoading || isAuthenticated === null ? (
        <StyledContainerLoader>
          <StyledLoader type="Bars" color="#2AFC98" height={100} width={100} />
        </StyledContainerLoader>
      ) : (
        <StyledPageWrapper>
          <StyledDarkSection>
            <img
              src={isMobile ? LandingSmall : LandingBig}
              alt="Online Soccer Game"
            />
            <StyledHeader>
              <StyledLeftHeaderSection>
                <LOGO />
              </StyledLeftHeaderSection>
            </StyledHeader>

            <StyledSection>
              <StyledTitle>
                Casual football manager game for the football{' '}
                enthusiast
              </StyledTitle>
              <StyledSubtitle>
                The Open League is a social, easy-to-play, football manager game
                fully integrated with Discord for lovers of the beautiful sport
                who don’t have all day to mess around
              </StyledSubtitle>
              <StyledRightHeaderSection>
                <StyledMobileRegisterButton
                  id="register-link"
                  primary
                  rounded
                  small
                  onClick={clickRegister}
                >
                  <MetaPixel />
                  Register
                </StyledMobileRegisterButton>
                <StyledMobileLoginButton
                  dark
                  onClick={() => {
                    clickLogin();
                  }}>
                  | Login |
                </StyledMobileLoginButton>
                <StyledButtonsContainer>
                  <StyledLinksWrapper>
                    <StyledStoreLink
                      onClick={() =>
                        goToUrl('https://play.google.com/store/apps/details?id=com.theopenleague&hl=en_US')
                      }
                    >
                      <img src={DownloadGooglePlay} alt="Get it on Google Play" />
                    </StyledStoreLink>
                    <StyledStoreLink
                      onClick={() =>
                        goToUrl('https://apps.apple.com/br/app/the-open-league/id6593708943?l=en-GB')
                      }
                    >
                      <img src={DownloadAppStore} alt="Download on the App Store" />
                    </StyledStoreLink>
                    <StyledRegisterButton
                      id="register-link"
                      primary
                      rounded
                      small
                      onClick={clickRegister}
                    >
                      <MetaPixel />
                      Register
                    </StyledRegisterButton>
                    <StyledLoginButton
                      dark
                      onClick={() => {
                        clickLogin();
                      }}>
                      | Login |
                    </StyledLoginButton>
                    {/* |
                    <StyledExternalLink
                      white
                      onClick={() => {
                        clickBlog();
                      }}
                    >
                      Blog
                    </StyledExternalLink> */}
                  </StyledLinksWrapper>
                </StyledButtonsContainer>
              </StyledRightHeaderSection>
            </StyledSection>
            {/* <StyledFeatured>
              {isMobile && (
                <StyledSpan small>
                  Featured in at least one of these fine publications
                </StyledSpan>
              )}
              {!isMobile && (
                <StyledSpan>
                  Featured in at least one of these fine publications*
                </StyledSpan>
              )}
              <StyledFeaturedImageContainer>
                <StyledImage src={Athletic} alt="Athletic" />
                <StyledImage src={GMGames} alt="GMGames" />
                <StyledImage src={Guardian} alt="Guardian" />
                <StyledImage src={TechCrunch} alt="TechCrunch" />
                <StyledImage src={TheSun} alt="TheSun" />
              </StyledFeaturedImageContainer>
              {!isMobile && (
                <StyledSpan small>
                  *Yes, this is totally deceptive - we are just testing how
                  effective adding these brands is at making you Register for
                  The Open League
                </StyledSpan>
              )}
            </StyledFeatured> */}
          </StyledDarkSection>
          <StyledElipse />
          <StyledDetailsContainer>
            <StyledImgContainer center>
              <StyledImage
                noShadow
                src={DiscordGameplay}
                alt="Manager's messages in Discord"
              />
              <StyledDescriptionHeader>
                The first football manager on Discord
              </StyledDescriptionHeader>
              <StyledDescriptionSubheader>
                The Open League is the most social football manager ever,
                so everything happens on Discord: daily matches,
                negotiations, and of course, chatting with the community.
              </StyledDescriptionSubheader>
            </StyledImgContainer>
            <StyledImgContainer center>
              <StyledImage
                noShadow
                src={SimpleGameplay}
                alt="Strategy and formations options in the game app"
              />
              <StyledDescriptionHeader>
                Simple gameplay and interface
              </StyledDescriptionHeader>
              <StyledDescriptionSubheader>
                You don’t need to watch 30 tutorials to thrive in The Open League.
                This game was built for football fans with limited time on their hands,
                but that don't abdicate of competition and fun.
              </StyledDescriptionSubheader>
            </StyledImgContainer>
            <StyledImgContainer center>
              <StyledImage
                noShadow
                src={PlayOnline}
                alt="Eleven start selection using the game app"
              />
              <StyledDescriptionHeader>
                Play online anytime, anywhere
              </StyledDescriptionHeader>
              <StyledDescriptionSubheader>
                Apart from this app, you can manage your club virtually from any
                online device with a browser. You can also find us on Steam.
              </StyledDescriptionSubheader>
            </StyledImgContainer>
            <StyledImgContainer center>
              <StyledImage
                noShadow
                src={DailyFootball}
                alt="Claire's messages in Discord"
              />
              <StyledDescriptionHeader>
                Daily football matches
              </StyledDescriptionHeader>
              <StyledDescriptionSubheader>
                League matches are simulated daily with text highlights streaming real-time on Discord. A season lasts three weeks. During the off-season, teams scout and sign youth talent.
              </StyledDescriptionSubheader>
            </StyledImgContainer>
            {/* <StyledDescriptionContainer>

              <StyledLandingButton dark small onClick={clickRegister}>
                Join now
              </StyledLandingButton>
            </StyledDescriptionContainer> */}
          </StyledDetailsContainer>
          {/* <StyledDetailsContainer grey>
            <StyledDescriptionContainer>
              <StyledDescriptionHeader>
                Daily football matches
              </StyledDescriptionHeader>
              <StyledDescriptionSubheader>
                League matches are simulated daily with text highlights
                streaming real-time on Discord. A season lasts three weeks. Top
                teams are promoted, bottom ones are demoted. During the
                off-season, teams register for youth camp where they scout and
                sign youth talent.
              </StyledDescriptionSubheader>
              <StyledLandingButton dark small onClick={clickRegister}>
                Join now
              </StyledLandingButton>
            </StyledDescriptionContainer>
            <StyledImgContainer center>
              <StyledImage small src={DailyMatch} alt="Daily matches" />
            </StyledImgContainer>
          </StyledDetailsContainer> */}
          {/* <StyledDetailsContainer>
            <StyledImgContainer halfSize center>
              <StyledImage noShadow src={DiscordScreen} alt="Discord" />
            </StyledImgContainer>
            <StyledDescriptionContainer halfSize>
              <StyledDescriptionHeader>
                First football manager integrated with Discord
              </StyledDescriptionHeader>
              <StyledDescriptionSubheader>
                We built The Open League to be the friendliest football manager
                game ever and we couldn’t do that without integrating it fully
                with Discord. Managers follow daily matches on the platform,
                they negotiate transfers with other managers, and just come to
                chill and kill time. We’ve also built bots who can answer your
                questions and help you navigate TOL.
              </StyledDescriptionSubheader>
              <StyledLandingButton brand onClick={clickRegister}>
                Join our server now
              </StyledLandingButton>
            </StyledDescriptionContainer>
          </StyledDetailsContainer> */}
          {/* <StyledDetailsContainer grey>
            <StyledDescriptionContainer>
              <StyledDescriptionHeader>
                Free to play forever. No pay-to-win.
              </StyledDescriptionHeader>
              <StyledDescriptionSubheader>
                We all love free stuff - The Open League is free for everyone
                forever. We offer pro subsciptions to our most dedicated users
                however these features do not give managers an edge in the game.
                We hate pay-to-win and lootbox scams.
              </StyledDescriptionSubheader>
              <StyledLandingButton primary small onClick={clickRegister}>
                Join now
              </StyledLandingButton>
            </StyledDescriptionContainer>
            <StyledImgContainer center>
              <StyledImage small src={PatreonSubscription} alt="Patreon" />
            </StyledImgContainer>
          </StyledDetailsContainer> */}
          {/* <StyledDetailsContainer>
            <StyledImgContainer halfSize right>
              <StyledImage
                noShadow
                src={MultiDeviceScreen}
                alt="Free To Play"
              />
            </StyledImgContainer>
            <StyledDescriptionContainer halfSize>
              <StyledDescriptionHeader>
                Play online anytime,
                <br /> anywhere.
              </StyledDescriptionHeader>
              <StyledDescriptionSubheader>
                You can manage your club from virtually any online device with a
                browser. We are also working on new platforms (Steam, Desktop,
                and Mobile apps).
              </StyledDescriptionSubheader>

              <StyledLandingButton dark small onClick={clickRegister}>
                Join now
              </StyledLandingButton>
            </StyledDescriptionContainer>
          </StyledDetailsContainer> */}
          <StyledFooterContainer>
            <StyledCenteredContainer>
              <StyledTextNavLink
                to="/terms"
                target="_blank"
                rel="noreferrer noopener"
                href="#"
              >
                <span>Terms of use</span>
              </StyledTextNavLink>
              <StyledTextNavLink
                to="/privacy"
                target="_blank"
                rel="noreferrer noopener"
                href="#"
              >
                <span>Privacy</span>
              </StyledTextNavLink>
              <StyledMailLink
                target="_blank"
                rel="noreferrer noopener"
                href="mailto:Chris@theopenleague.com"
              >
                <span>Contact Us</span>
              </StyledMailLink>
              <StyledExternalLink
                green
                onClick={() => goToUrl('https://twitter.com/playopenleague')}
              >
                <span>Twitter</span>
              </StyledExternalLink>
              <StyledExternalLink
                green
                onClick={() =>
                  goToUrl('https://www.instagram.com/theopenleague/')
                }
              >
                <span>Instagram</span>
              </StyledExternalLink>
              <StyledExternalLink
                green
                onClick={() =>
                  goToUrl('https://www.facebook.com/TheOpenLeague')
                }
              >
                <span>Facebook</span>
              </StyledExternalLink>
              <StyledTextNavLink
                onClick={() => {
                  clickBlog();
                }}
                to="/blog"
                rel="noreferrer noopener"
                href="#"
              >
                <span>Blog</span>
              </StyledTextNavLink>
            </StyledCenteredContainer>
          </StyledFooterContainer>
        </StyledPageWrapper>
      )}
    </>
  );
};

export default StartPage;
